.linha{
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
}

h1{
  text-align: center;
}

.App{
  margin: auto;
  max-width: 900px;
}

.rodape {
  text-align: center;
}