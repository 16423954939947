.conversor{
    padding: 20px;
    max-width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

select {
    margin: 10px;
    padding: 10px;
    font-size: large;
    font-weight: bold;
}